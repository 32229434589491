import styled from "styled-components";
import img1 from '../images/old-normal/1.png';
import img2 from '../images/old-normal/2.png';
import img3 from '../images/old-normal/3.png';
import img4 from '../images/old-normal/4.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';

const OldNormal = (props) => {
    let imgs = [];
    return (
        <>
            <Transitions>

                <Container>
                    <Title>
                        The Old Normal
                    </Title>
                    <Content>
                        <TextRow>
                            This series of images were highlighted ten years after being captured to show the
                            transformations that took place in what it means to be social. The series was born coincidentally,
                            at a time when social media was only in the process of entering our lives. The images were not
                            intended to tackle particular topics but to show memories of a random social day. Nowadays,
                            everyone is debating about the impact of social media on our views, lifestyles, and perceptions
                            of the world, and how it directs our growth and openness, but on the other hand, how it
                            controls and misleads us. Whether we like it or not, social media is here to stay. But the
                            questions that still linger are:
                            <Space />
                            How come creating <i>memories</i> become <i>new posts?</i>
                            <Space />
                            Are we our true selves when creating <i>memories</i> or <i>stories?</i>
                            <Space />
                            Why would humans as social beings substitute real intimacy with visual presence?
                            <Space />
                            To this end, there is covert pressure on individuals to show off their daily routines, what they eat,
                            who they meet, or where they go. Those who do not comply with this <i>new era</i> may feel left out
                            and not adaptable enough.
                            <Space />
                            <i>The Old Normal</i> is a series of photographs that depicts an ordinary day of a young individual
                            living the moment with no ambitions to be seen by hundreds or thousands of people. The
                            pictures were saved in a memory box to reflect particular moments the old normal full of joyful
                            emotions and recklessness.
                            <Space />
                        </TextRow>
                        <TextRow opacity={`0.5`}>
                            Series consists of 26 analog photographs 10x15 cm, C-type print
                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Pictures src={img1}></Pictures>
                        <Row>
                            <Column>
                                <Pictures src={img2}></Pictures>

                            </Column>
                            <Column>
                                <Pictures src={img3}></Pictures>

                            </Column>
                        </Row>
                        <Pictures src={img4}></Pictures>

                    </ImageContainer>
                </Container>
            </Transitions>
        </>
    );
}

export default OldNormal;