import styled from "styled-components";

export const Container = styled.div`
margin-top:16px;
width: 100%;
display:flex;
flex-direction:column;
`;
export const Title = styled.div`
font-style: normal;
font-weight: 400;
font-size: 48px;
line-height: 84px;
color: #000000;
font-family: 'Calmetta Trial';
@media (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
  
  }
`;
export const Content = styled.div`
margin-top:30px;
font-family: 'Arsenal';

`;
export const TextRow = styled.p`
margin: 0px;
padding: 0px;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 26px;
opacity: ${props => props.opacity ? props.opacity : '1'};
width: 60%;
@media (max-width: 576px) {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  
  }
`;
export const ImageContainer = styled.div`
width:100%;
margin-top: 60px;
display: flex;
flex-direction: column;
gap: 31px;
@media (max-width: 576px) {
  gap: 8px; 
}
`;
export const Column = styled.div`
display:flex;
flex-direction: column;
align-items: flex-start;
gap: 31px;
@media (max-width: 576px) {
  gap: 8px; 
}
`;

export const Row = styled.div`
width:100%;
height: fit-content;
display:flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
gap: 31px;
@media (max-width: 576px) {
    flex-direction: column;  
    gap: 8px;
  }
`;

export const Pictures = styled.img`
width:100%;
`;


export const Space = styled.div`
height: 24px;
`;