import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import leftArrow from '../images/left-arrow.png';
import rightArrow from '../images/right-arrow.png';
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
const Container = styled.div`
width:100%;
display:flex;
flex-direction: column;
gap: 16px;
margin-bottom: 32px;
`;

const Navigation = styled.div`
width: 100%;
display: flex;
flex-direction : row;
gap: 50px;
align-items: center;
@media (max-width: 576px) {
   gap: 20px;
   flex-direction: column;
   align-items: flex-start;
  
  }
`;

const Navbar = styled.div`
display: flex;
flex-direction : row;
gap: 12px;


`;


const Title = styled.div`
font-family: 'Calmetta Trial';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 58px;
color: #000000;
cursor: pointer;
@media (max-width: 576px) {
    font-size: 24px;
  
  }
`;

const NavItem = styled.div`
font-style: normal;
font-family: 'Calmetta Trial';
font-weight: 400;
font-size: 20px;
line-height: 34px;
opacity: ${props => props.selected ? '1' : '0.5'};
text-transform: capitalize;
padding-right: 2px;
border-bottom : ${props => props.selected ? '2px solid black' : 'none'};
cursor : pointer;
&:hover{
    transform: scale(1.03);
}
@media (max-width: 576px) {
   font-size:14px;
}
`;

const ArrowBar = styled.div`
width:100%;
display: flex;
flex-direction: row;
align-items:center;
justify-content: ${props => props.alignRight ? 'flex-end' : 'space-between'};

`
const Arrow = styled.img`
width: 24px;
heiht: 24px;
color: #000000;
cursor: pointer;

&:hover{
    transform: scale(1.1);

}
@media (max-width: 576px) {
    width: 16px;
 }

`;

const Topbar = (props) => {
    const pages = ['work', 'about', 'contact'];
    const [selectedPage, setSelectedPage] = useState('work');
    const [params, setParams] = useSearchParams();
    const location = useLocation();
    const workIndex = parseInt(params.get('w'));
    const navigate = useNavigate();
    const divStyle = {
        display: workIndex >= 0 ? 'none' : 'block',
    };

    useEffect(() => {
        switch (location.pathname) {
            case '/about':
                setSelectedPage('about');
                break;
            case '/contact':
                setSelectedPage('contact');
                break;         


            default:
                break;
        }

    }, [location])

    const navigateWork = (val) => {
        navigate(`/work/${props.works[workIndex + val]}?w=${workIndex + val}`)
    }
    return (
        <Container>
            <Navigation>
                {(selectedPage === 'work') && <Title style={divStyle} onClick={() => { navigate('/work') }}>
                    Özgür Ilter
                </Title>}
                <Navbar>
                    {pages.map((page, index) => {
                        return <NavItem key={index} selected={selectedPage === page} onClick={() => {
                            setSelectedPage(page);
                            navigate(`/${page}`);
                        }}>{page}</NavItem>
                    })}
                </Navbar>

            </Navigation>
            <ArrowBar alignRight={workIndex === 0} >
                {workIndex - 1 >= 0 && <Arrow src={leftArrow} alt="1" onClick={() => navigateWork(-1)}></Arrow>}
                {workIndex + 1 < props.works.length && <Arrow src={rightArrow} alt="2" onClick={() => navigateWork(1)}></Arrow>}
            </ArrowBar>
        </Container>
    );
}

export default Topbar;