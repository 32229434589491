import styled from "styled-components";
import img1 from '../images/obscurity/1.png';
import img2 from '../images/obscurity/2.png';
import img3 from '../images/obscurity/3.png';
import img4 from '../images/obscurity/4.png';
import img5 from '../images/obscurity/5.png';
import img6 from '../images/obscurity/6.png';
import img7 from '../images/obscurity/7.png';
import img8 from '../images/obscurity/8.png';
import img9 from '../images/obscurity/9.png';
import img10 from '../images/obscurity/10.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';


const Obscurity = (props) => {
    let imgs = [];
    return (
        <>
            <Transitions>

                <Container>
                    <Title>
                        From Obscurity to Particularity
                    </Title>
                    <Content>
                        <TextRow>
                            Individuals interact with places by physical experience, and their perception shapes through
                            these structures by the events or memories. From the earliest time of history, the desire for
                            closed space, in which humans have sought refuge to protect themselves from natural harm,
                            has become an integral part of everyday life. Through these intinctions, followingly, interactions,
                            originally and commonly attributed identity of places shifts its notion.
                            <Space />
                            The railway station, <i>Keleti Pályaudvar</i>, has been present for more than a hundred years. The
                            course of events that occurred in the city that it is located is historically significant. Today it is
                            functioning as a transit area linking one place to another. For a non-local individual, it is nearly
                            impossible not to get impressed by this architectural structure and its function. However, for a
                            local, it is only a part of the city’s ordinary architectural texture.
                            <Space />
                            For this purpose, understanding the relationship of the architectural structure with the perceiver
                            has been the starting point of this study by grounding it in a way to examine their identities from
                            past to present in connection with the passing time. The differences in these perceptions lead us
                            to think about the concept of a sense of belonging.
                            <Space />
                            Thus, Keleti Pályaudvar has been an efficient exemplar to tackle the notion through the subjects
                            of time, space, and human to conclude a better comprehension, objectify in the form of a
                            masterpiece coming into existing as a book and assembling theory with the practice in a
                            harmonized and visually pleasing result.
                            <Space />
                        </TextRow>
                        <TextRow opacity={`0.5`}>
                            2019 Group Exhibition, <i>METU Diploma</i>, A38 Gallery, Budapest, HU
                            <Space />
                            14,8x21 cm, hardcover book
                            Series consists of 5 illustrations 10,5x14,5 cm, watercolour, fineliner on rag paper
                            Series consists of 24 photographs, 2019 14,5x25,5 cm in print on offset paper, and Rives series
                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Pictures src={img1}></Pictures>
                        <Row>
                            <Column width='35' >
                                <Pictures src={img2}></Pictures>
                                <Pictures src={img4}></Pictures>
                            </Column>
                            <Column width='60'>
                                <Pictures src={img3}></Pictures>
                                <Pictures src={img5}></Pictures>
                            </Column>
                        </Row>
                        <Pictures src={img6}></Pictures>
                        <Row>
                            <Column>
                                <Pictures src={img7}></Pictures>
                            </Column>
                            <Column >
                                <Pictures src={img8}></Pictures>
                            </Column>
                        </Row>
                        <Pictures src={img9}></Pictures>
                        <Pictures src={img10}></Pictures>
                    </ImageContainer>
                </Container>
            </Transitions>
        </>
    );
}

export default Obscurity;