import styled from "styled-components";
import img1 from '../images/moments/1.png';
import img2 from '../images/moments/2.png';
import img3 from '../images/moments/3.png';
import img4 from '../images/moments/4.png';
import img5 from '../images/moments/5.png';
import img6 from '../images/moments/6.png';
import img7 from '../images/moments/7.png';
import img8 from '../images/moments/8.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';


const Moments = (props) => {
    let imgs = [];
    return (
        <>
            <Transitions>

                <Container>
                    <Title>
                        Non-Ephemeral Moments
                    </Title>
                    <Content>
                        <TextRow>
                            The series of images reflects the practices of everyday life, conceptualizing here and now.
                            <Space />
                            The placeless scenes objectify feeling-based notions and combine them with details and
                            individuals to express solitariness in that particular moment.
                            <Space />
                            Placelessness and solitariness are the elements that unify the concept of this work. It attempts
                            to attribute meaning and portray the character as living in the moment through self-perception.
                            In this context, the visual images reflect spontaneous blinks of an eye without fictionalizing
                            while reviving the feelings of stability, calmness, non-ephemeral moments relief, and serenity
                            that make the moment.
                            <Space />
                        </TextRow>
                        <TextRow opacity={`0.5`}>
                            Series consists of 30 analog photographs 13x18 cm, C-type Printing
                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Pictures src={img1}></Pictures>
                        <Row>
                            <Column>
                                <Pictures src={img2}></Pictures>
                                <Pictures src={img4}></Pictures>

                            </Column>
                            <Column>
                                <Pictures src={img3}></Pictures>
                                <Pictures src={img5}></Pictures>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <Pictures src={img6}></Pictures>
                                <Pictures src={img8}></Pictures>

                            </Column>
                            <Column>
                                <Pictures src={img7}></Pictures>
                            </Column>
                        </Row>

                    </ImageContainer>
                </Container>
            </Transitions>
        </>
    );
}

export default Moments;