import styled from "styled-components";
import img1 from '../images/here/1.png';
import img2 from '../images/here/2.png';
import img3 from '../images/here/3.png';
import img4 from '../images/here/4.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';


const Here = (props) => {
    let imgs = [];
    return (
        <>
            <Transitions>

                <Container>
                    <Title>
                        Here and There
                    </Title>
                    <Content>
                        <TextRow>
                            <i>Here and There</i> expresses the present moment in a journey that stands between the past and
                            the future, symbolizing the state of in-betweenness. The series depicts scenes of rather faraway
                            places that we override on our way to certain destinations. Scenes that we usually do not pay
                            attention to because we have no meaning attached to them.
                            <Space />
                            The visual snippets of these places entice a swift connection that produces feelings of
                            attachment, making these unintentional moments of connectedness unique, memorable, and
                            enjoyable.
                            <Space />
                            The visual shifting and sliding of the images tell a story of how we cling to places here and there
                            and moments to - in the bigger picture – forget how temporary everything is.
                            <Space />
                        </TextRow>
                        <TextRow opacity={`0.5`}>
                            Series consists of 3 photographs 5184x3486 px, digital
                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Pictures src={img1}></Pictures>
                        <Row>
                            <Column>
                                <Pictures src={img2}></Pictures>

                            </Column>
                            <Column>
                                <Pictures src={img3}></Pictures>

                            </Column>
                        </Row>
                        <Pictures src={img4}></Pictures>

                    </ImageContainer>
                </Container>

            </Transitions>
        </>
    );
}

export default Here;