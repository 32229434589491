import styled from "styled-components";
import OzgurPhoto from '../images/ozgur-ilter.png';
import Transitions from "./Transition";
const Container = styled.div`
display:flex;
flex-direction : column;
align-items:flex-start;
justify-content: space-between;
margin-top:168px;
@media (max-width: 576px) {
    margin-top:88px;
  }
overflow: auto;
`;
const Column = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
min-width: ${(props) => props.width + 'px' || '100%'};
@media (max-width: 992px) {
    min-width: 100%;
  }

`;

const Row = styled.div`
display:flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
@media (max-width: 992px) {
    flex-direction: column-reverse;
  }

`;

const Title = styled.h3`
margin: 0;
padding: 0;
font-family: 'Arsenal';
font-style: bold;
font-weight: 700;
font-size: 18px;
line-height: 26px;
@media (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
  
  }
`;
const Text = styled.p`
margin: 0;
padding: 0;
font-family: 'Arsenal';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 26px;
text-align: left;
@media (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;  
  }
`;
const Photo = styled.img`
width: 300px;
border:none;
outline:none;
margin: 53px 0px 0px 110px;
@media (max-width: 992px) {
  margin: 0px 0px 53px 0px;
  }
@media (max-width: 576px) {
    width: 200px;
    margin: 0px 0px 53px 0px;
  }
`;

const Space = styled.div`
height: 24px;
`;

const Href = styled.a`
color: black;
font-weight: 600;
text-decoration: none;
&:visited {
  color: black; 
}
`;

const About = (props) => {
  return (
    <>
      <Transitions>
        <Container>          
          <Row>
            <Column>
              <Title>/ Bio</Title>
              <Space />
              <Text>
                Özgür Ilter is a visual artist and designer, born in Ankara, currently based in Budapest. Her recent works mainly tackle the changing dynamics in individuals’ perceptions
                inspired by her own standing as a non-local living in a foreign country. She is interested in framing
                these inspirational topics with cultural, sociological, and anthropological aspects concerning her
                journey, life experiences, and experiments.
                <Space />
                For this purpose, she approaches the interactions between individuals, environments, and places
                to develop a deeper understanding of the rather complicated relationship between people and their
                immediate surroundings.
                <Space />
                Ilter works with photography, illustration, and moving images as approaches to depict her vision
                of such interactions. Both her research and artistic work are parts of her ongoing practice-based
                doctorate at the Hungarian University of Fine Arts.
                <Space />
                For a comprehensive overview of her artistic journey, please refer to her CV <Href href="https://drive.google.com/file/d/1c_8-2qrNiFKaVxBgVVtn70NSZppQzY2T/view?usp=sharing" target="_blank">here</Href>.
              </Text>
            </Column>
            <Column width={550}>
              <Photo src={OzgurPhoto} />
            </Column>
          </Row>







        </Container>
      </Transitions>
    </>
  )
}
export default About;