import img1 from '../images/alliance/1.png';
import img2 from '../images/alliance/2.png';
import img3 from '../images/alliance/3.png';
import img4 from '../images/alliance/4.png';
import img5 from '../images/alliance/5.png';
import img6 from '../images/alliance/6.png';
import img7 from '../images/alliance/7.png';
import img8 from '../images/alliance/8.png';
import img9 from '../images/alliance/9.png';
import img10 from '../images/alliance/10.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';


const Alliance = (props) => {
    return (
        <>
            <Transitions>
                <Container>
                    <Title>
                        Alliance
                    </Title>
                    <Content>
                        <TextRow>
                            <i>Alliance</i> is a remarkable exposition stemming from the fusion of two dynamic community workshops. Amidst the backdrop of this event lies a potent catalyst, stoking the fires of human perception, emotion, and existence. In the heart of downtown Budapest, 16 gifted individuals from diverse backgrounds converged to give voice to their distinctive community narratives. Their explorations into identity, history, and the human experience culminated in an exquisite series of linoleum prints.
                            <Space />
                            Across their creative journey and introspection, this cohort forged a tight-knit alliance, coalescing into a vibrant community. Together, they meticulously crafted captivating linoleum prints, a tangible testament to their shared consciousness.
                            <Space />
                            Embarking upon the realm of linoleum carving and printmaking, the majority of these artists embraced this novel artistic medium. With deft carvings, they immortalized cherished memories and profound imprints.
                            <Space />
                            The exhibition, curated and overseen the by the visual artists and illustrators Özgür Ilter and Ayşegül Ipek, commemorating the art of community-building and the exceptional creations that blossomed forth. As the artworks delve into the evolving essence of place and community over the passage of time, this showcase beckons us into a sphere of collaborative and communal engagement.
                            <Space />
                            Nestled within the embrace of Szabad Bistro, a vibrant epicenter of artistry, this exhibition waves us to uncover the nexus of art, community, and personal chronicles, embarking upon a voyage suffused with inspiration and the joys of artistic exploration, all born from workshops held within the annual Nyitva Festival, a cherished event gracing the city of Budapest.
                            <Space />
                        </TextRow>
                        <TextRow opacity={'0.5'}>
                            2023 Workshop Exhibition, Alliance, Szabad Bistro, Budapest, HU
                            <Space />
                            Linoleum carving and printing workshop series within the framework of 2023 Nyitva Festival
                            <Space />
                            Participants: <i>Ana Karen Allende (MEX), Ailbhe Reilly-Tuite (IRL), Andi Varga (HUN), Audette Amin Hyder (SYR), Aysel Hüseynova (AZE), Ayşegül Ipek (TUR), Charlotte Wong (HKG), Gina Rubik (IND), Gréta Kudela (HUN), Laila Azzeh (JOR), Laura Colnaghi (ITA), Özgür Ilter (TUR), Richard Stone Biodanza (IRL), Sára Gonda (HUN), Sára Vera Bodor (HUN), Seyhan Has Yardimli (TUR)</i>
                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Pictures src={img1}></Pictures>
                        <Row>
                            <Column>
                                <Pictures src={img2}></Pictures>
                            </Column>
                            <Column>
                                <Pictures src={img3}></Pictures>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <Pictures src={img4}></Pictures>
                                <Pictures src={img5}></Pictures>

                            </Column>
                            <Column>
                                <Pictures src={img6}></Pictures>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <Pictures src={img7}></Pictures>
                            </Column>
                            <Column>
                                <Pictures src={img8}></Pictures>
                            </Column>
                        </Row>
                        <Row>
                            <Pictures src={img9}></Pictures>

                        </Row>
                        <Row>
                            <Pictures src={img10}></Pictures>

                        </Row>
                    </ImageContainer>
                </Container>
            </Transitions>
        </>
    );
}

export default Alliance;