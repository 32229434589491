import styled from "styled-components";
import OzgurPhoto from '../images/ozgur-ilter.png';
import Transitions from "./Transition";
const Container = styled.div`
display:flex;
flex-direction : row;
align-items:flex-start;
justify-content: space-between;
margin-top:200px;
margin-top:168px;
@media (max-width: 576px) {
    margin-top:88px;
  }
`;
const Column = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items:flex-start;
`;
const Title = styled.h3`
display: inline;
margin: 0;
padding: 0;
font-family: 'Arsenal';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 26px;
`;
const Text = styled.p`
display: inline;
margin: 0;
padding: 0;
font-family: 'Arsenal';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 26px;
@media (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;  
  }
`;

const Space = styled.div`
height: 13px;
`;

const Href = styled.a`
color: black;
font-weight: 600;
text-decoration: none;
&:visited {
  color: black; 
}
`;

const Contact = (props) => {
    return (
        <>
        <Transitions>
            <Container>
                <Column>
                    <Text>              
                        <strong>/ LinkedIn:</strong> ozgur-ilter
                        <Space/>                       
                        <strong>/ Instagram:</strong> shutterbuga
                        <Space/>                    
                        <Space/>
                        <Space/>
                        To get in touch for any inquiries, questions, or collaboration opportunities, please don't hesitate to reach out via email at <Href href='mailto: hello@ozgurilter.com'>hello@ozgurilter.com</Href> 
                    </Text>
                </Column>
            </Container>
            </Transitions>
        </>
    )
}
export default Contact;