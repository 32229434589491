import styled from "styled-components";
import img1 from '../images/forget-me/1.png';
import img2 from '../images/forget-me/2.png';
import img3 from '../images/forget-me/3.png';
import img4 from '../images/forget-me/4.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';

const ForgetMe = (props) => {
    return (
        <>
            <Transitions>
                <Container>
                    <Title>
                        Forget-me-not / Nefelejcs
                    </Title>
                    <Content>
                        <TextRow>
                            In the realm of the observer beyond the veil, the unfolding narrative takes shape, woven delicately like the threads of existence themselves. The exposition named <i>Nefelejcs</i> emerges as a heartfelt homage, a tapestry of emotions unfurled in honor of those swept away by the cruel tremors that rocked the lands of Turkey and Syria on the fated day of February 6, 2023. It is a tender and sincere gesture, a chorus of souls endeavoring to transcend the confines of time and space.
                            <Space />
                            Within the boundless gallery of collective consciousness, Nefelejcs breathes life into a panorama of grief and resilience. As if whispered through the ages, its purpose manifests in a symphony of visuals, an orchestrated tribute to the fallen, and a harmonious resonance with those who endure. Each artistic piece adorning the gallery walls is a portal, a window into the raw, unfiltered human experience, inviting beholders to journey through the tapestry of emotions painted on the canvas of heartache.
                            <Space />
                            Among the luminous constellations of creators, names like Özgür Ilter, Audette Hyder, Simge Çağlı, Hannan Saif, and Tarek Arabi converge, their artistic alchemy melding to craft a realm of contemplation, serving as ethereal bridges connecting the tangible with the intangible, gently unfolding stories written in strokes of sorrow, hues of resilience, and shades of hope.
                            <Space />
                            But the veil thins, and Nefelejcs, the embodiment of the exhibition itself, emerges as a voice, a lament that traverses the ethereal realm and the tangible world. The installation by Ilter, bearing the same name, transcends its physical form, echoing the silent cries of homes lost, of communities uprooted, and the poignant melodies of identity severed from the earth. Memory, fragile and yet unyielding, finds its sanctuary within Nefelejcs, a mosaic of forgotten stories and whispered legacies.
                            <Space />
                            As conversations between realms intertwine, echoes of empathy resonate through the hearts of the beholders. The tendrils of Nefelejcs reach beyond the exhibition's confines, beckoning observers to embrace the depth of loss and the urgency of remembrance. The dance of emotions plays out as conversations ripple through the galleries, like whispers among kindred spirits, and the importance of safeguarding the fragments of the past becomes a shared revelation.
                            <Space />
                            Within this transcendental dialogue, Ilter, known as Nefelejcs, assumes her place as both creator and conduit, giving form to intangible sensations and pouring them onto the canvas of reality. The forget-me-not flowers, symbols of remembrance, bloom within the observer's minds, as she weaves her narrative, a meditation on perseverance etched upon fragile paper. In this act, she stitches memories into the fabric of consciousness, transforming pain into a source of collective strength.
                            <Space />
                            In the grand tapestry of existence, Nefelejcs emerges as a poignant chapter, a testament to the resilience of the human spirit in the face of tragedy. It is a mirror that reflects the depth of sorrow, the strength of survival, and the unwavering determination to remember and heal. Through its exposition, the narrative concludes with an indelible truth—a reminder that even amidst the harshest of trials, art, like a guiding star, illuminates the path toward empathy, understanding, and the possibility of renewal.
                            <Space />
                        </TextRow>
                        <TextRow opacity={'0.5'}>
                            2023 Group Exhibition, <i>Forget-me-not/Nefelejcs</i>, Profil Gallery, Budapest, HU
                            <Space />
                            Installation work containing 10 potted forget-me-not flowers inserted in paper-maché molds covered with cement, combined with typographic cut-outs of the words ‘perseverance’ and its translation in Hungarian, ‘allhatatosság’ on 240 gr. 40x80 cm paper



                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Row><Pictures src={img1}></Pictures></Row>
                        <Row><Pictures src={img2}></Pictures></Row>
                        <Row><Pictures src={img3}></Pictures></Row>
                        <Row><Pictures src={img4}></Pictures></Row>
                    </ImageContainer>
                </Container>
            </Transitions>
        </>
    );
}

export default ForgetMe;