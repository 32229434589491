import styled from "styled-components";
import gif from '../images/asthma/asthma.gif';
import img1 from '../images/asthma/1.png';
import img2 from '../images/asthma/2.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';


const Asthma = (props) => {
    let imgs = [];
    return (
        <>
            <Transitions>

                <Container>
                    <Title>
                        Asthma
                    </Title>
                    <Content>
                        <TextRow>
                            The artwork itself portrays dead ends as a reflection of asthma as a disease, taking the artist’s
                            personal experience as a starting point. In this sense, some questions came up throughout the
                            birth of the idea of the work concerning our deepest fears and the way we deal with them from
                            childhood and beyond.
                            <Space />
                            Having such congenital defects becomes a stalemate. On the other hand, it also teaches us how
                            to cope with certain conditions and develop resilience. So, is it possible for us to know who we
                            might be without these flaws?
                            <Space />
                            <i>Asthma</i>, as an artwork, is designed to describe the chronology of an asthma attack with all its
                            darkness and eventual triumph – resilience, strength and perseverance.
                            <Space />
                        </TextRow>
                        <TextRow opacity={`0.5`}>
                            20x20 cm, booklet printed on craft paper<br />
                            Series consists of 14 illustrations 20x20 cm, ink on rag paper
                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Pictures src={gif}></Pictures>
                        <Pictures src={img1}></Pictures>
                        <Pictures src={img2}></Pictures>

                    </ImageContainer>
                </Container>
            </Transitions></>
    );
}

export default Asthma;