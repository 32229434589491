import styled from "styled-components";
import img1 from '../images/island/1.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';


const Island = (props) => {
    let imgs = [];
    return (
        <>
            <Transitions>


                <Container>
                    <Title>
                        Ada Kalmasın Sensiz / Don’t Leave the Island Without You
                    </Title>
                    <Content>
                        <TextRow>
                            <i>Don’t Leave the Island Without You </i> (originally <i>Ada Kalmasin Sensiz</i>) is a short bio-pic video
                            tackling the life story of Tugrul Cakar, a successful Turkish photographer and writer who
                            depicted social issues and acculturation difficulties in Turkey through his works.
                            <Space />
                            The video shows an interview with him focusing on social transformation, while narrating some
                            quotes from his published essays in which he criticized social issues, and how he reflected them
                            in his photography works, also visualizes the studio that he used to keep his artworks, awards,
                            and collections.
                            <Space />
                            The place, which he named Shelter (Siginak), was his haven until he passed away before having
                            the chance to see the final version of the project. The video was named after an old Turkish song
                            that Cakar felt very attached to.
                            <Space />
                        </TextRow>
                        <TextRow opacity={`0.5`}>
                            Bio-pic, 8’ 44’’
                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Pictures src={img1}></Pictures>

                    </ImageContainer>
                </Container>
            </Transitions>
        </>
    );
}

export default Island;