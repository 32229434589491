import styled from "styled-components";
import img1 from '../images/human/1.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';

const Human = (props) => {
    let imgs = [];
    return (
        <>
            <Transitions>

                <Container>
                    <Title>
                        Are We Human?
                    </Title>
                    <Content>
                        <TextRow>
                            <i>Are We Human?</i> is an experimental installation work named after the theme of the 3rd Istanbul
                            Design Biennial, in which it was exhibited. The installation itself has a sarcastic expression,
                            showing a horse portrait at the center of a blank canvas. The portrait is covered by red acetate
                            paper that horizontally hung on the eyes, illustrating symbols taken from ancient civilizations
                            located in the old Anatolian region to reflect the issue of women commodification.
                            <Space />
                            Choosing to put blinders on the horse eyes is a way to demonstrate an old Turkish saying that
                            shows how objectifying women is a cause the society is blind to.
                            <Space />
                            The installation was placed as a one piece of work with a note asking visitors to fill the canvas
                            with their interpretations of the topic through their drawings or writings. By the involvement of
                            the audience, the outcomes were notably intriguing. Participants wrote passages from poems,
                            quotations, slacker sentences, attributions, and slogans about women’s rights and sexual
                            orientations in various languages, and they drew caricatures, religious symbols, obscene memes.
                            <Space />
                            The work of art takes its final form with experimental and interactive participation in which the
                            viewer’s interpretive, semantic, and inferential perceptions were on display.
                            <Space />
                        </TextRow>
                        <TextRow opacity={`0.5`}>
                            2016 3rd International Design Biennale, Are We Human? ITU, Istanbul, TR<br />
                            Installation consists of 30x30 cm watercolour illustration centered on 100x200 cm canvas
                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Pictures src={img1}></Pictures>

                    </ImageContainer>
                </Container>
            </Transitions>
        </>
    );
}

export default Human;