import styled from "styled-components";
import img1 from '../images/replace/1.png';
import img2 from '../images/replace/2.png';
import img3 from '../images/replace/3.png';
import img4 from '../images/replace/4.png';
import img5 from '../images/replace/5.png';
import img6 from '../images/replace/6.png';
import img7 from '../images/replace/7.png';
import img8 from '../images/replace/8.png';
import img9 from '../images/replace/9.png';
import img10 from '../images/replace/10.png';
import img11 from '../images/replace/11.png';
import Transitions from "./Transition";
import {Container, Title, Content, TextRow, Space, Row, ImageContainer,Column, Pictures} from './styled';



const Replace = (props) => {
    return (
        <>
            <Transitions>


                <Container>
                    <Title>
                        Re:place
                    </Title>
                    <Content>
                        <TextRow>
                            Physical experiences shape the way beings perceive the tangible terrene, and their sense of
                            belonging arises from the memories brought along. A single place, in its essence, might be
                            apodictic. But when shaped through an individual’s perception, it is incomparably idiosyncratic. In
                            this context, crossing into others' bubbles of reality elevates one's level of consciousness.
                            <Space />
                            From the earliest times of history, the desire for closed space, in which humans have sought refuge
                            to protect themselves from natural harm, has become an integral part of everyday life. Through
                            these instincts and the interactions that follow, the identity of place shifts its notion in a
                            universally unique sense.
                            <Space />
                            Home, stripped from every notion outside of its door when it’s shut, is what each individual finds
                            within, carries along, and cultivates throughout their journey. Therefore, the concept of location is
                            futile. Be that as it may, the impact of the whereabouts of individuals molds their persona
                            irreversibly through acquired memories.
                            <Space />
                            Special thanks to the following names for their collaboration and being the parts of Re:place:
                            <Space />
                            <i>
                                Ana Karen Allende (MEX), Baysaa P. (MNG), Tommy Philavong (LAO), Joana Viana (PRT),
                                Denisa Mihalisková (SVK), Paula Sousa (BRA), Marysia Ananiewska (RUS), Allison Hadden (MEX),
                                Tamás Szűcs (HUN), Zolzaya Boldbaatar (MNG), Sri Pyae Kyaw (MMR), Stacy Sample (USA),
                                Demet Damla Şeker (TUR), Mutondwa Nemuranuoni (ZAF), Roberta Schwambach (BRA),
                                Mar Calma (SLV)</i>
                            <Space />

                        </TextRow>
                        <TextRow opacity={`0.5`}>
                            2022 Group Exhibition, <i>The Great Sturgeons Turn</i>, Fészek Artist’s Club, Budapest, HU
                            Installation, Overlapping series of videos consists of 8 interviews with personal objects
                            <Space />
                            2022 Group Exhibition, <i>Parallel Hungary II</i>, MKE Profil Gallery, Budapest, HU
                            Installation, Interview video screening showcased with 60 photographs
                        </TextRow>
                    </Content>
                    <ImageContainer>
                        <Pictures src={img1}></Pictures>
                        <Row>
                            <Column>
                                <Pictures src={img2}></Pictures>
                                <Pictures src={img4}></Pictures>
                                <Pictures src={img6}></Pictures>
                                <Pictures src={img8}></Pictures>
                            </Column>
                            <Column>
                                <Pictures src={img3}></Pictures>
                                <Pictures src={img5}></Pictures>
                                <Pictures src={img7}></Pictures>
                                <Pictures src={img9}></Pictures>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <Pictures src={img10}></Pictures>
                            </Column>
                            <Column>
                                <Pictures src={img11}></Pictures>
                            </Column>
                        </Row>
                    </ImageContainer>
                </Container>
            </Transitions>
        </>
    );
}

export default Replace;