
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import img1 from '../images/replace/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/4.png';
import img5 from '../images/5.png';
import img6 from '../images/6.png';
import img7 from '../images/7.png';
import img8 from '../images/8.png';
import img9 from '../images/9.png';
import img10 from '../images/10.png';
import Transitions from './Transition';
import ImagePlaceHolder from '../imagePlaceHolder';

const Container = styled.div`
width:100%;
display:flex;
flex-direction: row;
gap: 2%;
flex-wrap : wrap;
margin-top:125px;
align-items:center;
justify-content: space-between;
@media (max-width: 576px) {
    flex-direction: column;
    gap: 8px;
  }

`;

const Img = styled.img`

width: 49%;
padding: 0;
cursor: pointer;
filter : grayscale(1.5);
transition: .2s ease-in-out;
margin-bottom: 2%;
&:hover{
    filter: grayscale(0);
    transform: scale(1.005);
}
@media (max-width: 576px) {
    width: 100%;
  
  }



`;

const Space = styled.div`
height: 24px;
`;

const MainPage = (props) => {
    const images = [img10, img9, img1, img2, img3, img4, img5, img6, img7, img8]
    const navigate = useNavigate();
    return (

        <>
            <Transitions>
                <Container>
                    {images.map((image, index) => {
                        return <Img src={image} key={index} onClick={() => {
                            navigate(`/work/${props.works[index]}?w=${index}`)
                        }} />
                    })}
                </Container>
            </Transitions>
        </>
    )
}
export default MainPage;